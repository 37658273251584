<template>
  <b-card :class="['friends__card', { 'friends__card--highlighted': parseInt(friend.highlighted) }]">
    <b-card-img v-if="friend.imagepath" :src="friend.imagepath" :alt="`friend-${friend.id}`" top/>
    <b-card-title>{{ friend.title }}</b-card-title>
    <b-card-text v-html="friend.description"/>

    <div v-if="displayIcons" class="d-flex justify-content-end">
      <b-button :variant="parseInt(friend.highlighted) ? 'primary' : 'inverted'"
                :to="`/friends/edit/${friend.id}`"
                class="mr-1"
      >
        <font-awesome-icon icon="edit"/>
      </b-button>
      <b-button :variant="parseInt(friend.highlighted) ? 'primary' : 'inverted'"
                @click="$emit('delete-friend', friend)"
      >
        <font-awesome-icon icon="trash"/>
      </b-button>
    </div>
  </b-card>
</template>
<script>
export default {
  name: 'FriendsCard',
  props: {
    friend: {
      type: Object,
      required: true
    },
    displayIcons: {
      type: Boolean,
      default: false
    }
  }
}
</script>
