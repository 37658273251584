<template>
  <div class="musicians__content d-flex flex-column mb-3">
    <div class="d-flex">
      <div class="musicians__content-image mr-3 object-contain">
        <img :src="musician.imagepath">
      </div>
      <div class="musicians__content-text">
        <h4>{{ musician.name }}</h4>
        <div v-html="musician.description"/>
      </div>
    </div>
    <div v-if="displayIcons" class="d-flex align-self-end">
      <b-button variant="primary"
                :to="`/musicians/edit/${musician.id}`"
                class="mr-1"
      >
        <font-awesome-icon icon="edit"/>
      </b-button>
      <b-button variant="primary" @click="$emit('delete-musician', musician)">
        <font-awesome-icon icon="trash"/>
      </b-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MusicianCard',
  props: {
    musician: {
      type: Object,
      required: true
    },
    displayIcons: {
      type: Boolean,
      default: false
    }
  }
}
</script>
