<template>
  <div v-if="!orderSent" class="order-form">
    <h5>Anschrift</h5>
    <b-row class="mt-2">
      <b-col cols="12">
        <div :class="['form__element', { 'form__element--error': $v.order.salutation.$error }]">
          <b-form-radio-group class="d-flex" v-model="$v.order.salutation.$model" :options="['Herr', 'Frau']"/>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <div :class="['form__element', { 'form__element--error': $v.order.firstname.$error }]">
          <label class="form__element-label">Vorname *</label>
          <b-form-input v-model="$v.order.firstname.$model"/>
          <div v-if="!$v.order.firstname.required" class="form__error-label">Das Feld muss ausgefüllt sein</div>
        </div>
      </b-col>
      <b-col cols="6">
        <div :class="['form__element', { 'form__element--error': $v.order.lastname.$error }]">
          <label class="form__element-label">Nachname *</label>
          <b-form-input v-model="$v.order.lastname.$model"/>
          <div v-if="!$v.order.lastname.required" class="form__error-label">Das Feld muss ausgefüllt sein</div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="9">
        <div :class="['form__element', { 'form__element--error': $v.order.address.$error }]">
          <label class="form__element-label">Strasse *</label>
          <b-form-input v-model="$v.order.address.$model"/>
          <div v-if="!$v.order.address.required" class="form__error-label">Das Feld muss ausgefüllt sein</div>
        </div>
      </b-col>
      <b-col cols="3">
        <div :class="['form__element', { 'form__element--error': $v.order.houseNr.$error }]">
          <label class="form__element-label">Nr *</label>
          <b-form-input v-model="$v.order.houseNr.$model"/>
          <div v-if="!$v.order.houseNr.required" class="form__error-label">Das Feld muss ausgefüllt sein</div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <div class="form__element">
          <label class="form__element-label">Adresszusatz</label>
          <b-form-input v-model="order.addressAddition"/>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="3">
        <div :class="['form__element', { 'form__element--error': $v.order.zip.$error }]">
          <label class="form__element-label">PLZ *</label>
          <b-form-input v-model="$v.order.zip.$model"/>
          <div v-if="!$v.order.zip.required" class="form__error-label">Das Feld muss ausgefüllt sein</div>
        </div>
      </b-col>
      <b-col cols="6">
        <div :class="['form__element', { 'form__element--error': $v.order.city.$error }]">
          <label class="form__element-label">Ort *</label>
          <b-form-input v-model="$v.order.city.$model"/>
          <div v-if="!$v.order.city.required" class="form__error-label">Das Feld muss ausgefüllt sein</div>
        </div>
      </b-col>
      <b-col cols="3">
        <div class="form__element d-flex flex-column">
          <label class="form__element-label">Land *</label>
          <vue-country-code @onSelect="setCountry" :preferredCountries="['ch', 'de', 'at']"/>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <div :class="['form__element', { 'form__element--error': $v.order.email.$error }]">
          <label class="form__element-label">E-mail *</label>
          <b-form-input v-model="$v.order.email.$model" type="email"/>
          <div v-if="!$v.order.email.required" class="form__error-label">Das Feld muss ausgefüllt sein</div>
          <div v-if="!$v.order.email.email" class="form__error-label">Das Feld muss eine gültige E-mail Adresse sein</div>
        </div>
      </b-col>
    </b-row>
    <div class="d-flex justify-content-end">
      <b-button variant="inverted" class="mr-2" to="/shop">Zurück</b-button>
      <b-button variant="primary"
                :disabled="$v.order.$invalid"
                @click="sendOrder"
      >
        Bestellen
      </b-button>
    </div>
  </div>
  <div v-else>
    <div v-if="orderSettled">
      Die Bestellung wurde erfolgreich abgeschickt.
      Sie erhalten in kürze eine Bestätigungsmail auf die angegebene E-mail Adresse.
    </div>
    <div v-else>
      Die Bestellung ist fehlgeschlagen.
      Bitte überprüfen Sie ihre Angaben oder kontaktieren Sie den Support.
    </div>
  </div>
</template>
<script>
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'OrderForm',
  props: {
    items: {
      type: Array,
      required: true
    },
    cost: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      orderSent: false,
      orderSettled: false,
      order: {
        salutation: '',
        firstname: '',
        lastname: '',
        address: '',
        houseNr: '',
        addressAddition: '',
        zip: '',
        city: '',
        country: '',
        email: ''
      }
    }
  },
  validations: {
    order: {
      salutation: { required },
      firstname: { required },
      lastname: { required },
      address: { required },
      houseNr: { required },
      addressAddition: {},
      zip: { required },
      city: { required },
      email: { required, email }
    }
  },
  computed: {
    formattedItems () {
      let output = '<table style="border-collapse: collapse; width: 75%; height: 83px;" border="1">'
      output += '<tbody>'
      output += '<tr style="height: 18px;">'
      output += '<td style="width: 60%; height: 18px;"><strong>Bezeichnung</strong></td>'
      output += '<td style="width: 22%; height: 18px;"><strong>Preis</strong></td>'
      output += '<td style="width: 18%; height: 18px;"><strong>Menge</strong></td>'
      output += '</tr>'

      this.items.forEach(item => {
        output += '<tr style="height: 18px;">'
        output += `<td style="width: 60%; height: 18px;">${item.title}</td>`
        output += `<td style="width: 22%; height: 18px;">CHF ${item.price}</td>`
        output += `<td style="width: 18%; height: 18px;">${item.amount}</td>`
        output += '</tr>'
      })

      output += '<tr style="height: 18px;">'
      output += '<td style="width: 60%; height: 18px;">&nbsp;</td>'
      output += '<td style="width: 22%; height: 18px;"><strong>Preis Total:</strong></td>'
      output += `<td style="width: 18%; height: 18px;"><strong>CHF ${this.cost}</strong></td>`
      output += '</tr></tbody></table>'

      return output
    },
    formattedContact () {
      let order = this.order
      let output = '<p>'
      output += `${order.salutation} ${order.firstname} ${order.lastname}`
      output += `<br/>${order.address} ${order.houseNr}`
      if (order.addressAddition) {
        output += `<br/>${order.addressAddition}`
      }
      output += `<br/>${order.country}-${order.zip} ${order.city}</p>`
      output += `<p>${order.email}</p>`

      return output
    }
  },
  methods: {
    setCountry (country) {
      this.order.country = country.iso2
    },
    async sendOrder () {
      const response = await this.$http.post(
        window.location.origin + '/php/send_email.php',
        {
          type: 'order',
          email: this.order.email,
          items: this.formattedItems,
          contact: this.formattedContact
        }
      )

      this.orderSent = true
      if (response.status === 200 && response.data.sent) {
        this.orderSettled = true
      }
    }
  }
}
</script>
