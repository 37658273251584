<template>
  <b-row v-if="fetchedItem" class="edit-shop mb-3">
    <b-col lg="8" md="12" class="edit-shop__form">
      <h3>Formular</h3>
      <div :class="['form__element', { 'form__element--error': $v.item.image.$error }]">
        <label class="form__element-label">Bild</label>
        <div class="d-flex">
          <b-form-file v-model="$v.item.image.$model"
                       placeholder="Bild auswählen..."
                       drop-placeholder="Bild hier ablegen..."
                       browse-text="Durchsuchen"
                       accept="image/*"
                       @change="encodeImage"
          />
          <b-button v-if="hasImage" @click="removeImage"><font-awesome-icon icon="times"/></b-button>
        </div>
      </div>
      <div :class="['form__element', { 'form__element--error': $v.item.title.$error }]">
        <label class="form__element-label">Titel *</label>
        <b-form-input v-model="$v.item.title.$model"/>
        <div v-if="!$v.item.title.required" class="form__error-label">Das Feld muss ausgefüllt sein</div>
        <div v-if="!$v.item.title.maxLength" class="form__error-label">Es sind maximal 50 Zeichen erlaubt</div>
      </div>
      <div :class="['form__element', { 'form__element--error': $v.item.description.$error }]">
        <label class="form__element-label">Inhalt *</label>
        <ckeditor v-model="$v.item.description.$model"/>
        <div v-if="!$v.item.description.required" class="form__error-label">Das Feld muss ausgefüllt sein</div>
        <div v-if="!$v.item.description.maxLength" class="form__error-label">Es sind maximal 500 Zeichen erlaubt</div>
      </div>
      <div :class="['form__element', { 'form__element--error': $v.item.price.$error }]">
        <label class="form__element-label">Preis *</label>
        <b-form-input v-model="$v.item.price.$model"/>
        <div v-if="!$v.item.price.required" class="form__error-label">Das Feld muss ausgefüllt sein</div>
        <div v-if="!$v.item.price.decimal" class="form__error-label">Der Wert des Feldes muss numerisch sein</div>
      </div>
      <div class="d-flex justify-content-end">
        <b-button variant="inverted" to="/shop" class="mr-2">Abbrechen</b-button>
        <b-button variant="inverted" @click="this.resetItem" class="mr-2">Zurücksetzen</b-button>
        <b-button variant="primary"
                  :disabled="$v.item.$invalid"
                  @click="updateItem"
        >
          Ändern
        </b-button>
      </div>
    </b-col>
    <b-col v-if="hasImage" lg="4" md="12" class="edit-shop__preview object-contain">
      <h3>Bild-Vorschau</h3>
      <b-img class="preview__image" :src="loadImage" alt="Preview-image"/>
    </b-col>
  </b-row>
  <div v-else>
    <h1>:(</h1>
    <div>Es existiert kein Eintrag mit Id: {{ $route.params.id }}</div>
    <b-button variant="inverted" to="/shop" class="mt-2">Zurück</b-button>
  </div>
</template>
<script>
import { required, decimal, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'EditShop',
  data () {
    return {
      item: {
        id: 0,
        image: null,
        imageBase64: null,
        title: '',
        description: '',
        price: null,
        imagepath: ''
      },
      fetchedItem: null
    }
  },
  validations: {
    item: {
      image: {},
      title: {
        required,
        maxLength: maxLength(50)
      },
      description: {
        required,
        maxLength: maxLength(500)
      },
      price: {
        required,
        decimal
      }
    }
  },
  async created () {
    const response = await this.$http.get(
      window.location.origin + '/php/GET_ShopItems.php',
      { params: { id: this.$route.params.id } }
    )

    if (response.status === 200 && response.data.length === 1) {
      this.fetchedItem = response.data[0]
      this.resetItem()
    } else {
      this.createToast('Unable to fetch data', 'danger')
    }
  },
  computed: {
    hasImage () {
      return this.item.imagepath || !!this.item.image
    },
    loadImage () {
      if (this.item.imageBase64) {
        return this.item.imageBase64
      }
      return this.item.imagepath
    }
  },
  methods: {
    encodeImage (value) {
      this.base64Encode(value.target.files[0]).then(value => {
        this.item.imageBase64 = value
      }).catch(() => {
        this.item.imageBase64 = null
      });
    },
    resetItem () {
      this.item = Object.assign(this.item, this.fetchedItem)
      this.item.image = null
      this.item.imageBase64 = null
      this.$v.item.$reset
    },
    removeImage () {
      this.item.imagepath = ''
      this.item.image = null
      this.$v.item.image.$touch()
    },
    async updateItem () {
      let formData = new FormData()
      formData.append('id', this.item.id)
      formData.append('title', this.item.title)
      formData.append('description', this.item.description)
      formData.append('price', this.item.price)
      formData.append('imageDirty', this.$v.item.image.$dirty)
      formData.append('imagepath', this.fetchedItem.imagepath)
      formData.append('image', this.item.image)

      const response = await this.$http.post(
        window.location.origin + '/php/PUT_ShopItems.php',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )

      if (response.status === 200) {
        this.createToast('Successfully updated data', 'success')
      } else {
        this.createToast('Unable to update data', 'danger')
      }

      this.$router.push('/shop')
    }
  }
}
</script>