<template>
  <div v-if="fetchedEvent" class="mb-3">
    <h3>Formular</h3>
    <div :class="['form__element', { 'form__element--error': $v.event.date.$error }]">
      <label class="form__element-label">Datum *</label>
      <b-form-datepicker v-model="$v.event.date.$model" placeholder=""/>
      <div v-if="!$v.event.date.required" class="form__error-label">Das Feld muss ausgefüllt sein</div>
    </div>
    <div :class="['form__element', { 'form__element--error': $v.event.time.$error }]">
      <label class="form__element-label">Zeitraum</label>
      <b-form-input v-model="$v.event.time.$model"/>
      <div v-if="!$v.event.description.maxLength" class="form__error-label">Es sind maximal 30 Zeichen erlaubt</div>
    </div>
    <div :class="['form__element', { 'form__element--error': $v.event.description.$error }]">
      <label class="form__element-label">Beschreibung *</label>
      <ckeditor v-model="$v.event.description.$model"/>
      <div v-if="!$v.event.description.required" class="form__error-label">Das Feld muss ausgefüllt sein</div>
      <div v-if="!$v.event.description.maxLength" class="form__error-label">Es sind maximal 500 Zeichen erlaubt</div>
    </div>
    <div class="d-flex justify-content-end">
      <b-button variant="inverted" to="/events" class="mr-2">Abbrechen</b-button>
      <b-button variant="inverted" @click="this.resetEvent" class="mr-2">Zurücksetzen</b-button>
      <b-button variant="primary"
                @click="updateEvent"
                :disabled="!$v.event.$anyDirty || $v.event.$invalid"
      >
        Ändern
      </b-button>
    </div>
  </div>
  <div v-else>
    <h1>:(</h1>
    <div>Es existiert kein Eintrag mit Id: {{ $route.params.id }}</div>
    <b-button variant="inverted" to="/events" class="mt-2">Zurück</b-button>
  </div>
</template>
<script>
import { required, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'EditEvent',
  data () {
    return {
      event: {
        date: null,
        time: '',
        description: ''
      },
      fetchedEvent: null
    }
  },
  validations: {
    event: {
      date: {
        required
      },
      time: {
        maxLength: maxLength(30)
      },
      description: {
        required,
        maxLength: maxLength(500)
      }
    }
  },
  async created () {
    const response = await this.$http.get(
      window.location.origin + '/php/GET_Events.php',
      { params: { id: this.$route.params.id } }
    )

    if (response.status === 200 && response.data.length === 1) {
      this.fetchedEvent = response.data[0]
      this.resetEvent()
    } else {
      this.createToast('Unable to fetch data', 'danger')
    }
  },
  methods: {
    resetEvent () {
      this.event = Object.assign(this.event, this.fetchedEvent)
      this.$v.event.$reset
    },
    async updateEvent () {
      const response = await this.$http.post(
        window.location.origin + '/php/PUT_Events.php',
        {
          id: this.event.id,
          date: this.event.date,
          time: this.event.time,
          description: this.event.description
        }
      )

      if (response.status === 200) {
        this.createToast('Successfully updated data', 'success')
      } else {
        this.createToast('Unable to update data', 'danger')
      }

      this.$router.push('/events')
    }
  }
}
</script>