import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuelidate from 'vuelidate'
import CKEditor from 'ckeditor4-vue'
import Axios from 'axios'
import BootstrapVue from 'bootstrap-vue'
import VueCountryCode from 'vue-country-code'
import VueSession from 'vue-session'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlus, faEdit, faTimes, faTrash, faShoppingCart, faDotCircle, faSignInAlt, faSignOutAlt, faBars } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

import NoDbConnection from './components/Filler/NoDbConnection.vue'

import './scss/_custom.scss'

import router from './router.js'
import toast from './mixins/toast.js'
import base64encode from './mixins/base64encode.js'
import App from './App.vue'

Vue.use(VueRouter)
Vue.use(Vuelidate)
Vue.use(BootstrapVue)
Vue.use(CKEditor)
Vue.use(VueCountryCode)
Vue.use(VueSession)

library.add(faPlus, faEdit, faTimes, faTrash, faShoppingCart, faDotCircle, faSignInAlt, faSignOutAlt, faBars)
library.add(faFacebook, faYoutube)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('no-db-connection', NoDbConnection)

Vue.mixin(toast)
Vue.mixin(base64encode)

Vue.prototype.$http = Axios;

new Vue({
  el: '#app',
  router,
  render: h => h(App)
})
