<template>
  <b-card class="d-flex flex-row mb-3" no-body>
    <b-row no-gutters>
      <b-col md="6">
        <b-card-img v-if="item.imagepath" :src="item.imagepath" :alt="`item-${item.id}`"/>
      </b-col>
      <b-col md="6">
        <b-card-body :title="item.title" class="d-flex flex-column h-100 p-3">
          <b-card-text v-html="item.description"/>
          <div class="d-flex justify-content-end mt-auto">
            <b-button-group>
              <b-button variant="primary" disabled>CHF {{ item.price }}</b-button>
              <b-button variant="inverted" @click="$emit('add-to-cart', item)">In den Warenkorb</b-button>
            </b-button-group>
          </div>
          <div v-if="displayIcons" class="d-flex justify-content-end mt-2">
            <b-button variant="primary" :to="`/shop/edit/${item.id}`" class="mr-1">
              <font-awesome-icon icon="edit"/>
            </b-button>
            <b-button variant="primary" @click="$emit('delete-item', item)">
              <font-awesome-icon icon="trash"/>
            </b-button>
          </div>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
export default {
  name: 'ShopItem',
  props: {
    item: {
      type: Object,
      required: true
    },
    displayIcons: {
      type: Boolean,
      default: false
    }
  }
}
</script>