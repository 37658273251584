<template>
  <div v-if="queryItems && queryItems.length" class="order mb-3">
    <div class="order__summary">
      <h5 class="mb-3">Sie sind dabei folgende Artikel zu bestellen:</h5>
      <b-table :items="mergedItems"
               :fields="fields"
               caption-top
               responsive
               striped
      >
        <template #cell(price)="row">
          CHF {{ row.item.price }}
        </template>

        <template #table-colgroup>
          <col>
          <col style="width: 15%">
          <col style="width: 20%">
        </template>

        <template #bottom-row>
          <td></td>
          <td class="text-right">Total:</td>
          <td>CHF {{ totalCost }}</td>
        </template>
      </b-table>
    </div>
    <order-form :items="mergedItems" :cost="totalCost"/>
  </div>
  <div v-else>
    <h1>:(</h1>
    <div>Etwas ist schief gelaufen.</div>
    <b-button variant="inverted" to="/shop" class="mt-2">Zurück</b-button>
  </div>
</template>
<script>
import OrderForm from './OrderForm.vue'

export default {
  name: 'Order',
  components: { OrderForm },
  data () {
    return {
      queryItems: this.$route.query.items ? JSON.parse(this.$route.query.items) : [],
      items: [],
      fields: [
        { label: 'Bezeichnung', key: 'title' },
        { label: 'Preis', key: 'price' },
        { label: 'Menge', key: 'amount' }
      ]
    }
  },
  computed: {
    mergedItems () {
      return this.items.map(item => ({
        ...this.queryItems.find((queryItem) => (queryItem.id === item.id) && item),
        ...item
      }))
    },
    totalCost () {
      const items = this.mergedItems
      if (!items.length) { return 0 }
      if (items.length === 1) {
        return items[0].price * items[0].amount
      }

      return items.reduce((accumulator, item) => ({
        price: (accumulator.amount * accumulator.price) + (item.amount * item.price),
        amount: 1
      })).price
    }
  },
  async created () {
    const itemIds = this.queryItems.map(item => item.id)
    const response = await this.$http.get(
      window.location.origin + '/php/GET_ShopItems.php',
      { params: { id: itemIds } }
    )

    if (response.status === 200) {
      this.items = response.data
    } else {
      this.createToast('Unable to fetch data', 'danger')
    }
  }
}
</script>
