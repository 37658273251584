export default {
  methods: {
    createToast (toastText, variant, toaster) {
      this.$root.$bvToast.toast(
        toastText,
        {
          title: variant === 'success' ? 'Success' : 'Error',
          toaster: toaster || 'b-toaster-bottom-left',
          variant: variant,
          solid: true,
          appendToast: true
        }
      )
    }
  }
}