<template>
  <div class="login">
    <h3>Login</h3>
    <div v-if="this.$session.get('logged-in')">
      Erfolgreich eingeloggt!
    </div>
    <div v-else class="login__form" @keyup.enter="login">
      <div :class="['form__element', { 'form__element--error': failed }]">
        <label class="form__element-label">Benutzername</label>
        <b-form-input v-model="username"/>
      </div>
      <div :class="['form__element', { 'form__element--error': failed }]">
        <label class="form__element-label">Passwort</label>
        <b-form-input type="password" v-model="password"/>
        <div class="form__error-label">Login fehlgeschlagen! Benutzername und Passwort stimmen nicht überein.</div>
      </div>
      <div class="d-flex justify-content-end">
        <b-button variant="primary" @click="login">Erstellen</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      username: '',
      password: '',
      failed: false
    }
  },
  methods: {
    async login () {
      const response = await this.$http.post(
        window.location.origin + '/php/login_script.php',
        { username: this.username, password: this.password }
      )

      if (response.status === 200 && response.data.successful) {
        this.$session.set('logged-in', true)
        this.$router.go()
      } else {
        this.password = ''
        this.failed = true
      }
    }
  }
}
</script>
