<template>
  <b-navbar class="main-nav" toggleable="lg">
    <b-container class="container--nav">
      <b-navbar-brand to="/">Im Stadel</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse">
        <font-awesome-icon icon="bars"/>
      </b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item-dropdown text="Über uns" :class="toggleClass">
            <b-dropdown-item to="/origin">Entstehung</b-dropdown-item>
            <b-dropdown-item to="/musicians">Die Musikanten</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item to="/events">Termine</b-nav-item>
          <b-nav-item to="/friends">Freunde</b-nav-item>
          <b-nav-item to="/videos">Videos</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item to="/shop">Shop</b-nav-item>
          <b-nav-item to="/contact">Kontakt</b-nav-item>
          <b-nav-item v-if="$session.get('logged-in')" @click="logout">
            <font-awesome-icon icon="sign-out-alt"/>
          </b-nav-item>
          <b-nav-item v-else to="/login">
            <font-awesome-icon icon="sign-in-alt"/>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-container>
  </b-navbar>
</template>

<script>
export default {
  name: 'Navigation',
  data() {
    return {
      aboutUsDropdown: ['/origin', '/musicians']
    }
  },
  computed: {
    toggleClass() {
      return this.aboutUsDropdown.indexOf(this.$route.path) > -1 ? 'dropdown-active' : ''
    }
  },
  methods: {
    async logout () {
      await this.$http.post(
        window.location.origin + '/php/login_script.php',
        { logout: true }
      )

      this.$session.destroy()
      this.$router.push('/')
      this.createToast('Successfully logged out', 'success')
    }
  }
}
</script>
