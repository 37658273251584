<template>
  <b-row v-if="fetchedFriend" class="edit-friend mb-3">
    <b-col lg="8" md="12" class="edit-friend__form">
      <h3>Formular</h3>
      <div :class="['form__element', { 'form__element--error': $v.friend.image.$error }]">
        <label class="form__element-label">Titelbild</label>
        <div class="d-flex">
          <b-form-file v-model="$v.friend.image.$model"
                       placeholder="Bild auswählen..."
                       drop-placeholder="Bild hier ablegen..."
                       browse-text="Durchsuchen"
                       accept="image/*"
                       @change="encodeImage"
          />
          <b-button v-if="hasImage" @click="removeImage"><font-awesome-icon icon="times"/></b-button>
        </div>
      </div>
      <div :class="['form__element', { 'form__element--error': $v.friend.title.$error }]">
        <label class="form__element-label">Titel *</label>
        <b-form-input v-model="$v.friend.title.$model"/>
        <div v-if="!$v.friend.title.required" class="form__error-label">Das Feld muss ausgefüllt sein</div>
        <div v-if="!$v.friend.title.maxLength" class="form__error-label">Es sind maximal 50 Zeichen erlaubt</div>
      </div>
      <div :class="['form__element', { 'form__element--error': $v.friend.description.$error }]">
        <label class="form__element-label">Inhalt *</label>
        <ckeditor v-model="$v.friend.description.$model"/>
        <div v-if="!$v.friend.description.required" class="form__error-label">Das Feld muss ausgefüllt sein</div>
        <div v-if="!$v.friend.description.maxLength" class="form__error-label">Es sind maximal 500 Zeichen erlaubt</div>
      </div>
      <div :class="['form__element', { 'form__element--error': $v.friend.highlighted.$error }]">
        <b-form-checkbox v-model="$v.friend.highlighted.$model">Hervorgehoben</b-form-checkbox>
      </div>
      <div class="d-flex justify-content-end">
        <b-button variant="inverted" to="/friends" class="mr-2">Abbrechen</b-button>
        <b-button variant="inverted" @click="this.resetFriend" class="mr-2">Zurücksetzen</b-button>
        <b-button variant="primary"
                  :disabled="$v.friend.$invalid"
                  @click="updateFriend"
        >
          Ändern
        </b-button>
      </div>
    </b-col>
    <b-col lg="4" md="12" class="edit-friend__preview">
      <h3>Vorschau</h3>
      <b-card :class="['friends__card', { 'friends__card--highlighted': friend.highlighted}]">
        <b-card-img v-if="hasImage" :src="loadImage" alt="Image" top/>
        <b-card-title>{{ friend.title }}</b-card-title>
        <b-card-text v-html="friend.description"/>
      </b-card>
    </b-col>
  </b-row>
  <div v-else>
    <h1>:(</h1>
    <div>Es existiert kein Eintrag mit Id: {{ $route.params.id }}</div>
    <b-button variant="inverted" to="/friends" class="mt-2">Zurück</b-button>
  </div>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'EditFriend',
  data () {
    return {
      friend: {
        id: 0,
        title: '',
        description: '',
        imagepath: '',
        highlighted: false,
        image: null,
        imageBase64: null
      },
      fetchedFriend: null
    }
  },
  validations: {
    friend: {
      title: {
        required,
        maxLength: maxLength(50)
      },
      description: {
        required,
        maxLength: maxLength(500)
      },
      image: {},
      highlighted: {}
    }
  },
  async created () {
    const response = await this.$http.get(
      window.location.origin + '/php/GET_Friends.php',
      { params: { id: this.$route.params.id } }
    )

    if (response.status === 200 && response.data.length === 1) {
      this.fetchedFriend = response.data[0]
      this.fetchedFriend.highlighted = !!parseInt(this.fetchedFriend.highlighted)
      this.resetFriend()
    } else {
      this.createToast('Unable to fetch data', 'danger')
    }
  },
  computed: {
    hasImage () {
      return this.friend.imagepath || !!this.friend.image
    },
    loadImage () {
      if (this.friend.imageBase64) {
        return this.friend.imageBase64
      }
      return this.friend.imagepath
    }
  },
  methods: {
    encodeImage (value) {
      this.base64Encode(value.target.files[0]).then(value => {
        this.friend.imageBase64 = value
      }).catch(() => {
        this.friend.imageBase64 = null
      });
    },
    resetFriend () {
      this.friend = Object.assign(this.friend, this.fetchedFriend)
      this.friend.image = null
      this.friend.imageBase64 = null
      this.$v.friend.$reset
    },
    removeImage () {
      this.friend.imagepath = ''
      this.friend.image = null
      this.$v.friend.image.$touch()
    },
    async updateFriend () {
      let formData = new FormData()
      formData.append('id', this.friend.id)
      formData.append('title', this.friend.title)
      formData.append('description', this.friend.description)
      formData.append('highlighted', this.friend.highlighted ? 1 : 0)
      formData.append('imageDirty', this.$v.friend.image.$dirty)
      formData.append('imagepath', this.fetchedFriend.imagepath)
      formData.append('image', this.friend.image)

      const response = await this.$http.post(
        window.location.origin + '/php/PUT_Friends.php',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )

      if (response.status === 200) {
        this.createToast('Successfully updated data', 'success')
      } else {
        this.createToast('Unable to update data', 'danger')
      }

      this.$router.push('/friends')
    }
  }
}
</script>
