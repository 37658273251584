<template>
  <b-row v-if="fetchedMusician" class="edit-musician mb-3">
    <b-col lg="8" md="12" class="edit-musician__form">
      <h3>Formular</h3>
      <div :class="['form__element', { 'form__element--error': $v.musician.image.$error }]">
        <label class="form__element-label">Bild</label>
        <div class="d-flex">
          <b-form-file v-model="$v.musician.image.$model"
                       placeholder="Bild auswählen..."
                       drop-placeholder="Bild hier ablegen..."
                       browse-text="Durchsuchen"
                       accept="image/*"
                       @change="encodeImage"
          />
          <b-button v-if="hasImage" @click="removeImage"><font-awesome-icon icon="times"/></b-button>
        </div>
        <div v-if="!$v.musician.image.required" class="form__error-label">Das Feld muss ausgefüllt sein</div>
      </div>
      <div :class="['form__element', { 'form__element--error': $v.musician.name.$error }]">
        <label class="form__element-label">Titel</label>
        <b-form-input v-model="$v.musician.name.$model"/>
        <div v-if="!$v.musician.name.required" class="form__error-label">Das Feld muss ausgefüllt sein</div>
        <div v-if="!$v.musician.name.maxLength" class="form__error-label">Es sind maximal 30 Zeichen erlaubt</div>
      </div>
      <div :class="['form__element', { 'form__element--error': $v.musician.description.$error }]">
        <label class="form__element-label">Inhalt</label>
        <ckeditor v-model="$v.musician.description.$model"/>
        <div v-if="!$v.musician.description.required" class="form__error-label">Das Feld muss ausgefüllt sein</div>
        <div v-if="!$v.musician.description.maxLength" class="form__error-label">Es sind maximal 600 Zeichen erlaubt</div>
      </div>
      <div class="d-flex justify-content-end">
        <b-button variant="inverted" to="/musicians" class="mr-2">Abbrechen</b-button>
        <b-button variant="inverted" @click="this.resetMusician" class="mr-2">Zurücksetzen</b-button>
        <b-button variant="primary"
                  :disabled="$v.musician.$invalid"
                  @click="updateMusician"
        >
          Ändern
        </b-button>
      </div>
    </b-col>
    <b-col v-if="hasImage" lg="4" md="12" class="edit-musician__preview object-contain">
      <h3>Bild-Vorschau</h3>
      <b-img class="preview__image" :src="loadImage" alt="Preview-image"/>
    </b-col>
  </b-row>
  <div v-else>
    <h1>:(</h1>
    <div>Es existiert kein Eintrag mit Id: {{ $route.params.id }}</div>
    <b-button variant="inverted" to="/musicians" class="mt-2">Zurück</b-button>
  </div>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'EditMusician',
  data () {
    return {
      musician: {
        id: 0,
        name: '',
        description: '',
        imagepath: '',
        image: null,
        imageBase64: null
      },
      fetchedMusician: null
    }
  },
  validations: {
    musician: {
      name: {
        required,
        maxLength: maxLength(30)
      },
      description: {
        required,
        maxLength: maxLength(600)
      },
      image: {
        required: function (value) {
          const imagepath = this.musician.imagepath
          return imagepath ? imagepath : !!value
        }
      }
    }
  },
  async created () {
    const response = await this.$http.get(
      window.location.origin + '/php/GET_Musicians.php',
      { params: { id: this.$route.params.id } }
    )

    if (response.status === 200 && response.data.length === 1) {
      this.fetchedMusician = response.data[0]
      this.resetMusician()
    } else {
      this.createToast('Unable to fetch data', 'danger')
    }
  },
  computed: {
    hasImage () {
      return this.musician.imagepath || !!this.musician.image
    },
    loadImage () {
      if (this.musician.imageBase64) {
        return this.musician.imageBase64
      }
      return this.musician.imagepath
    }
  },
  methods: {
    encodeImage (value) {
      this.base64Encode(value.target.files[0]).then(value => {
        this.musician.imageBase64 = value
      }).catch(() => {
        this.musician.imageBase64 = null
      });
    },
    resetMusician () {
      this.musician = Object.assign(this.musician, this.fetchedMusician)
      this.musician.image = null
      this.musician.imageBase64 = null
      this.$v.musician.$reset
    },
    removeImage () {
      this.musician.imagepath = ''
      this.musician.image = null
      this.$v.musician.image.$touch()
    },
    async updateMusician () {
      let formData = new FormData()
      formData.append('id', this.musician.id)
      formData.append('name', this.musician.name)
      formData.append('description', this.musician.description)
      formData.append('imageDirty', this.$v.musician.image.$dirty)
      formData.append('imagepath', this.fetchedMusician.imagepath)
      formData.append('image', this.musician.image)

      const response = await this.$http.post(
        window.location.origin + '/php/PUT_Musicians.php',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )

      if (response.status === 200) {
        this.createToast('Successfully updated data', 'success')
      } else {
        this.createToast('Unable to update data', 'danger')
      }

      this.$router.push('/musicians')
    }
  }
}
</script>
