<template>
  <div class="contact mb-3">
    <h3 class="contact__title">Kontakt</h3>
    <div class="contact__text">
      Bei Fragen bezüglich unseren Auftritten oder bei jeglichen Fragen,
      können Sie uns mit untenstehendem Formular, oder direkt per
      <b-link href="mailto:info@alpenstadel.ch">E-mail</b-link> kontaktieren.
    </div>
    <div class="contact__content-wrapper">
      <div class="contact__form mt-4">
        <b-row>
          <b-col cols="12">
            <div :class="['form__element', { 'form__element--error': $v.email.$error }]">
              <label class="form__element-label">E-mail adresse *</label>
              <b-form-input v-model="$v.email.$model" type="email"/>
              <div v-if="!$v.email.required" class="form__error-label">Das Feld muss ausgefüllt sein</div>
              <div v-if="!$v.email.email" class="form__error-label">Das Feld muss eine gültige E-mail Adresse sein</div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <div :class="['form__element', { 'form__element--error': $v.name.$error }]">
              <label class="form__element-label">Vor- und Nachname *</label>
              <b-form-input v-model="$v.name.$model" type="email"/>
              <div v-if="!$v.name.required" class="form__error-label">Das Feld muss ausgefüllt sein</div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <div :class="['form__element', { 'form__element--error': $v.inquiry.$error }]">
              <label class="form__element-label">Anliegen *</label>
              <b-form-textarea v-model="$v.inquiry.$model" rows="5"/>
              <div v-if="!$v.inquiry.required" class="form__error-label">Das Feld muss ausgefüllt sein</div>
            </div>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-end">
          <b-button variant="primary"
                    :disabled="$v.$invalid"
                    @click="sendInquiry"
          >
            Absenden
          </b-button>
        </div>
      </div>
      <div class="contact__social-media justify-content-around">
        <b-link href="https://www.facebook.com/Alpenstadel-Musikanten-219125231499553" target="_blank" class="facebook mx-auto">
          <font-awesome-icon :icon="['fab', 'facebook']" size="5x"/>
        </b-link>
        <b-link href="https://www.youtube.com/channel/UCiRD0R0Y8qcg-JuGzvXAw8g" target="_blank" class="youtube mx-auto">
          <font-awesome-icon :icon="['fab', 'youtube']" size="5x"/>
        </b-link>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'Contact',
  data () {
    return {
      email: "",
      name: "",
      inquiry: ""
    }
  },
  validations: {
    email: { required, email },
    name: { required },
    inquiry: { required }
  },
  methods: {
    async sendInquiry () {
      const response = await this.$http.post(
        window.location.origin + '/php/send_email.php',
        {
          type: 'contact',
          email: this.email,
          name: this.name,
          inquiry: this.inquiry
        }
      )

      if (response.status === 200 && response.data.sent) {
        this.createToast('E-mail erfolgreich gesendet', 'success')
        this.$router.go()
      } else {
        this.createToast('E-mail konnte nicht gesendet werden', 'danger')
      }
    }
  }
}
</script>
