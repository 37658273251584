<template>
  <b-row class="new-musician mb-3">
    <b-col lg="8" md="12" class="new-musician__form">
      <h3>Formular</h3>
      <div :class="['form__element', { 'form__element--error': $v.image.$error }]">
        <label class="form__element-label">Bild *</label>
        <div class="d-flex">
          <b-form-file v-model="$v.image.$model"
                       placeholder="Bild auswählen..."
                       drop-placeholder="Bild hier ablegen..."
                       browse-text="Durchsuchen"
                       accept="image/*"
                       @change="encodeImage"
          />
          <b-button v-if="hasImage" @click="image = null"><font-awesome-icon icon="times"/></b-button>
        </div>
        <div v-if="!$v.image.required" class="form__error-label">Das Feld muss ausgefüllt sein</div>
      </div>
      <div :class="['form__element', { 'form__element--error': $v.name.$error }]">
        <label class="form__element-label">Name *</label>
        <b-form-input v-model="$v.name.$model"/>
        <div v-if="!$v.name.required" class="form__error-label">Das Feld muss ausgefüllt sein</div>
        <div v-if="!$v.name.maxLength" class="form__error-label">Es sind maximal 30 Zeichen erlaubt</div>
      </div>
      <div :class="['form__element', { 'form__element--error': $v.description.$error }]">
        <label class="form__element-label">Beschreibung *</label>
        <ckeditor v-model="$v.description.$model"/>
        <div v-if="!$v.description.required" class="form__error-label">Das Feld muss ausgefüllt sein</div>
        <div v-if="!$v.description.maxLength" class="form__error-label">Es sind maximal 600 Zeichen erlaubt</div>
      </div>
      <div class="d-flex justify-content-end">
        <b-button variant="inverted" to="/musicians" class="mr-2">Abbrechen</b-button>
        <b-button variant="primary"
                  @click="createMusician"
                  :disabled="!$v.$anyDirty || $v.$invalid"
        >
          Erstellen
        </b-button>
      </div>
    </b-col>
    <b-col v-if="hasImage" lg="4" md="12" class="new-musician__preview">
      <h3>Bild-Vorschau</h3>
      <b-img class="preview__image" :src="imageBase64" alt="Preview-image"/>
    </b-col>
  </b-row>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'NewMusician',
  data () {
    return {
      image: null,
      imageBase64: null,
      name: '',
      description: ''
    }
  },
  validations: {
    image: { required },
    name: {
      required,
      maxLength: maxLength(30)
    },
    description: {
      required,
      maxLength: maxLength(600)
    }
  },
  computed: {
    hasImage () {
      return !!this.image
    }
  },
  methods: {
    encodeImage (value) {
      this.base64Encode(value.target.files[0]).then(value => {
        this.imageBase64 = value
      }).catch(() => {
        this.imageBase64 = null
      });
    },
    async createMusician () {
      let formData = new FormData()
      formData.append('name', this.name)
      formData.append('description', this.description)
      formData.append('image', this.image)

      const response = await this.$http.post(
        window.location.origin + '/php/POST_Musicians.php',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )

      if (response.status === 200) {
        this.createToast('Successfully created data', 'success')
      } else {
        this.createToast('Unable to create data', 'danger')
      }

      this.$router.push('/musicians')
    }
  }
}
</script>
