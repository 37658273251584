<template>
  <div class="fourohfour">
    <h1>404</h1>
    Die Seite wurde leider nicht gefunden
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>
