<template>
  <div class="origin">
    <h3 class="origin__title">Unsere Geschichte...</h3>
    <div class="origin__text">
      <p>
        Wir waren alle im Sommer 2010 mit einer Gruppe Musikanten in St.
        Ulrich/Kärnten im Urlaub und musizierten was das Zeug hielt. Am
        Feuerwehrfest an der Bar entstand die Idee eine Band zu gründen und
        schnell waren die Mitglieder gefunden. Oberkrainer Musik und
        volkstümlicher Schlager sollte es sein. Halt eine Kapelle, die an
        verschiedenen Anlässen zum Tanz aufspielen kann. Nur eben live gespielt
        und von Herzen musste es kommen. Die Begeisterung war gross und
        ebenfalls die Herausforderung. Um heraus zu finden, ob dieses Vorhaben
        klappt, bestimmten wir das Stück "Die kleine Eisenbahn" von Slavko
        Avsenik. Anschliessend hatte jeder von uns den Auftrag die eigene Stimme
        zu üben. Im Oktober 2010 spielten wir das Lied zum ersten Mal gemeinsam
        in der heutigen Formation. Es funktionierte und wir waren begeistert.
        Bereits am 12. Oktober 2010 war die offizielle Gründung des Vereins
        "Alpenstadel Musikanten". Wir stellten gemeinsam ein Repertoire zusammen
        und begannen zu „üben, üben und nochmals üben“.
      </p>
      <h5 class="origin__subtitle">Es entsteht die erste CD</h5>
      <p>
        Nach diversen erfolgreichen Auftritten beschlossen wir, auf unsere erste
        CD hin zu arbeiten. Dank Sponsoring durch die RAIFFEISENBANK Regio
        Altnau konnten wir das Werk realisieren und wir sind stolz darauf.
        "Gruss nach Oberkrain" heisst sie, enthält 12 Lieder und wurde bereits
        mehrere hundert Mal verkauft. Mit der finanziellen Unterstützung von
        Universal-Job AG leisteten wir uns jedoch zuvor einen Workshop in der
        Hochburg der Oberkrainer Musik, in Begunje (SLO). Am 4. November 2012,
        also zwei Jahre nach der Gründung, konnten wir die „CD-Taufe“, dank der
        Gastfreundschaft von Boscho und Margrit Dubcevic, in einem sehr
        festlichen Rahmen mit einem begeisterten Publikum im damaligen Dancing
        „Klein Rigi“ in Schönenberg TG durchführen.
      </p>
      <h5 class="origin__subtitle">Ein Fan Club wird gegründet</h5>
      <p>
        Durch Zufall lernten wir im Jahr 2015 an einem unserer Auftritte
        Rosmarie Spreiter kennen. Aus Jux fragten wir sie, ob sie nicht Lust
        hätte einen Fan Club auf die Beine zu stellen. Und siehe da, sie hat
        spontan zugesagt. So freut sich die Präsidentin, dass der Verein bereits
        fast 100 Mitglieder zählt.
      </p>
      <h5 class="origin__subtitle">Dann folgt die zweite CD</h5>
      <p>
        Nach mehreren Ausbildungstagen mit Renato Verlič (Gitarrist der
        Hausmusik Avsenik) in Kärnten und auch in der Schweiz, machten wir uns
        im Januar 2016, gleich nach einem weiteren Auftritt im „Klein Rigi“, mit
        Sack und Pack mit dem Nachtzug auf nach Slowenien. Um 7.00h morgens
        trafen wir in Villach ein und fuhren dann direkt ins Tonstudio von Tomaz
        Hribar, wo wir von Renato und dem Aufnahmeleiter Jure Valjavec erwartet
        wurden. Sofort machten wir uns an die Arbeit und nach 4 Tagen waren die
        Aufnahmen im „Kasten“. Unsere zweite CD heisst „Sag es mit Musik“ und
        enthält 14 Titel.
      </p>
      <h5 class="origin__subtitle">10-Jahre Jubiläum</h5>
      <p>
        Am 5. September 2020 durften wir mit der Gastformation „Winti-Fäger“
        unser 10-Jahre Jubiläum feiern. Unter dem Motto «Oberkrainer und
        Schlager Tanzabend» erlebten wir zusammen mit unserem Fan Club und
        vielen treuen Freunden einen unvergesslichen Abend im Toggenburgerhof in
        Kirchberg SG.
      </p>
      <p>
        Wir blicken stolz und glücklich zurück auf unzählige Stunden voll Musik
        und freuen uns darauf, die schöne Oberkrainer und Schlagermusik weiter
        in die Welt hinaus zu tragen.
      </p>
      <h5 class="origin__subtitle">Änderung der Formation</h5>
      <p>
        Anita und Regula haben sich entschieden nach über 10 Jahren die
        Alpenstadel Musikanten zu verlassen. Ein grosser Verlust für die Band,
        aber die Freundschaft bleibt natürlich erhalten. Ganz im Sinne von
        «Änderungen sind Chancen» haben wir zu unserem grossen Glück zwei neue
        Kollegen gefunden. Ab April 2023 spielen wir Oberkrainer Musik mit
        original Instrumentierung. Ritsch Konatschnig als Trompeter ergänzt auch
        unsere Schlager Stücke am Schlagzeug und Peter Niggli als Klarinettist
        bringt zusätzlich mit dem Saxophon neue Farbe in unser Repertoir. Wir
        sind überglücklich in der neuen Besetzung durch zu starten und freuen
        uns weiterhin tolle Oberkrainer und Schlager Musik für unsere Fans und
        Gäste zu spielen. Wir danken Anita und Regula für die tolle Zeit und die
        Mitgründung der Alpenstadel Musikanten.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Origin",
};
</script>
