<template>
  <div class="friends">
    <div class="d-flex justify-content-between">
      <h3>Musik verbindet</h3>
      <b-button v-if="$session.get('logged-in')" variant="primary" to="/friends/new" class="mb-2">
        <font-awesome-icon icon="plus"/>
      </b-button>
    </div>
    <b-card-group columns>
      <friends-card v-for="friend in friends"
                    :key="friend.id"
                    :friend="friend"
                    :displayIcons="$session.get('logged-in')"
                    @delete-friend="deleteFriend"
      />
    </b-card-group>
  </div>
</template>

<script>
import FriendsCard from './FriendsCard.vue'

export default {
  name: 'Friends',
  components: { FriendsCard },
  data () {
    return {
      friends: null
    }
  },
  async created () {
    await this.fetchData()
  },
  methods: {
    async fetchData () {
      const response = await this.$http.get(window.location.origin + '/php/GET_Friends.php')
      if (response.status === 200) {
        this.friends = response.data
      } else {
        this.createToast('Unable to fetch data', 'danger')
      }
    },
    async deleteFriend (friend) {
      const h = this.$createElement
      const body = h('div', {}, [
        h('div', {}, ['Du bist dabei folgenden Datensatz zu löschen:']),
        h('p', { class: ['m-3'] }, [
          `Id: ${friend.id}`, h('br'),
          `Titel: "${friend.title}"`
        ])
      ])

      const confirmed = await this.$bvModal.msgBoxConfirm(body, {
        title: 'Datensatz löschen',
        okTitle: 'Löschen',
        cancelVariant: 'inverted',
        cancelTitle: 'Abbrechen',
        hideHeaderClose: true
      })

      if (confirmed) {
        const response = await this.$http.post(
          window.location.origin + '/php/DELETE_Friends.php',
          { id: friend.id, imagepath: friend.imagepath }
        )

        if (response.status === 200) {
          this.createToast('Successfully deleted data', 'success')
        } else {
          this.createToast('Unable to delete data', 'danger')
        }
      }

      this.fetchData()
    }
  }
}
</script>
