<template>
  <b-table class="mt-3"
           :items="items"
           :fields="fields"
           caption-top
           show-empty
           responsive
           striped
           hover
           stacked="sm"
  >
    <template #table-caption>{{ title }}</template>
    <template #empty>
      <div>{{ emptyText }}</div>
    </template>

    <template #cell(date)="data">{{ data.value | date }}</template>

    <template #cell(description)="data">
      <span v-html="data.value"/>
    </template>

    <template #cell(edit)="event">
      <b-button variant="primary" :to="`/events/edit/${event.item.id}`">
        <font-awesome-icon icon="edit"/>
      </b-button>
    </template>
    <template #cell(delete)="event">
      <b-button variant="primary" @click="$emit('delete-event', event.item)">
        <font-awesome-icon icon="trash"/>
      </b-button>
    </template>

    <template #table-colgroup>
      <col style="width: 120px">
      <col style="width: 120px">
      <col>
      <col style="width: 1px">
      <col style="width: 1px">
    </template>
  </b-table>
</template>
<script>
export default {
  name: 'EventsTable',
  props: {
    title: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    emptyText: {
      type: String,
      default: ''
    }
  },
  filters: {
    date (field) {
      const date = new Date(field)
      let day = date.getDate()
      let month = date.getMonth() + 1

      if (day < 10) day = '0' + day
      if (month < 10) month = '0' + month

      return `${day}.${month}.${date.getFullYear()}`
    }
  }
}
</script>