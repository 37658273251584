<template>
  <b-row class="new-friend mb-3">
    <b-col lg="8" md="12" class="new-friend__form">
      <h3>Formular</h3>
      <div :class="['form__element', { 'form__element--error': $v.image.$error }]">
        <label class="form__element-label">Titelbild</label>
        <div class="d-flex">
          <b-form-file v-model="$v.image.$model"
                       placeholder="Bild auswählen..."
                       drop-placeholder="Bild hier ablegen..."
                       browse-text="Durchsuchen"
                       accept="image/*"
                       @change="encodeImage"
          />
          <b-button v-if="hasImage" @click="image = null"><font-awesome-icon icon="times"/></b-button>
        </div>
      </div>
      <div :class="['form__element', { 'form__element--error': $v.title.$error }]">
        <label class="form__element-label">Titel *</label>
        <b-form-input v-model="$v.title.$model"/>
        <div v-if="!$v.title.required" class="form__error-label">Das Feld muss ausgefüllt sein</div>
        <div v-if="!$v.title.maxLength" class="form__error-label">Es sind maximal 50 Zeichen erlaubt</div>
      </div>
      <div :class="['form__element', { 'form__element--error': $v.description.$error }]">
        <label class="form__element-label">Inhalt *</label>
        <ckeditor v-model="$v.description.$model"/>
        <div v-if="!$v.description.required" class="form__error-label">Das Feld muss ausgefüllt sein</div>
        <div v-if="!$v.description.maxLength" class="form__error-label">Es sind maximal 500 Zeichen erlaubt</div>
      </div>
      <div :class="['form__element', { 'form__element--error': $v.highlighted.$error }]">
        <b-form-checkbox v-model="$v.highlighted.$model">Hervorgehoben</b-form-checkbox>
      </div>
      <div class="d-flex justify-content-end">
        <b-button variant="inverted" to="/friends" class="mr-2">Abbrechen</b-button>
        <b-button variant="primary"
                  @click="createFriend"
                  :disabled="!$v.$anyDirty || $v.$invalid"
        >
          Erstellen
        </b-button>
      </div>
    </b-col>
    <b-col v-if="$v.$anyDirty" lg="4" md="12" class="new-friend__preview">
      <h3>Vorschau</h3>
      <b-card :class="['friends__card', { 'friends__card--highlighted': highlighted}]">
        <b-card-img v-if="hasImage" :src="imageBase64" alt="Image" top/>
        <b-card-title>{{ title }}</b-card-title>
        <b-card-text v-html="description"/>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'NewFriend',
  data () {
    return {
      image: null,
      imageBase64: null,
      title: '',
      description: '',
      highlighted: false
    }
  },
  validations: {
    image: {},
    title: {
      required,
      maxLength: maxLength(50)
    },
    description: {
      required,
      maxLength: maxLength(500)
    },
    highlighted: {}
  },
  computed: {
    hasImage () {
      return !!this.image
    }
  },
  methods: {
    encodeImage (value) {
      this.base64Encode(value.target.files[0]).then(value => {
        this.imageBase64 = value
      }).catch(() => {
        this.imageBase64 = null
      })
    },
    async createFriend () {
      let formData = new FormData()
      formData.append('title', this.title)
      formData.append('description', this.description)
      formData.append('image', this.image)
      formData.append('highlighted', this.highlighted ? 1 : 0)

      const response = await this.$http.post(
        window.location.origin + '/php/POST_Friends.php',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )

      if (response.status === 200) {
        this.createToast('Successfully created data', 'success')
      } else {
        this.createToast('Unable to create data', 'danger')
      }

      this.$router.push('/friends')
    }
  }
}
</script>
