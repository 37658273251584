<template>
  <div class="shop">
    <div class="d-flex justify-content-between">
      <h3>Shop</h3>
      <div class="mb-2">
        <b-button variant="primary" v-b-modal.shopping-cart>
          <font-awesome-layers>
            <font-awesome-icon icon="shopping-cart"/>
            <font-awesome-icon v-if="cart.length" icon="dot-circle" class="shop__dot-circle ml-3 mb-3" transform="grow-5"/>
          </font-awesome-layers>
        </b-button>
        <b-button v-if="$session.get('logged-in')" variant="primary" to="/shop/new" class="ml-2">
          <font-awesome-icon icon="plus"/>
        </b-button>
      </div>
    </div>
    <shop-item v-for="item in shopItems"
               :key="item.id"
               :item="item"
               :display-icons="$session.get('logged-in')"
               @delete-item="deleteItem"
               @add-to-cart="addToCart"
    />

    <b-modal id="shopping-cart" body-class="p-0" size="lg">
      <template #modal-title>
        Warenkorb
      </template>
      <b-table :items="cart"
               :fields="cartFields"
               caption-top
               show-empty
               responsive
               striped
      >
        <template #empty>
          <div class="text-center">Es befinden sich keine Produkte im Warenkorb</div>
        </template>

        <template #cell(price)="row">
          CHF {{ row.item.price }}
        </template>

        <template #cell(amount)="row">
          <b-form-input v-model="row.item.amount" type="number" min="1"/>
        </template>

        <template #cell(remove)="row">
          <b-button variant="primary" @click="removeFromCart(row.item.id)">
            <font-awesome-icon icon="times"/>
          </b-button>
        </template>

        <template #bottom-row>
          <td></td>
          <td class="text-right">Total:</td>
          <td>CHF {{ totalCost }}</td>
        </template>

        <template #table-colgroup>
          <col>
          <col style="width: 15%">
          <col style="width: 20%">
          <col style="width: 1px">
        </template>
      </b-table>
      <template #modal-footer>
        <div class="d-flex justify-content-end">
          <b-button variant="primary" :disabled="!cart.length" :to="`/shop/order?items=${queryCart}`">
            Bestellen
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import ShopItem from './ShopItem.vue'

export default {
  name: 'Shop',
  components: { ShopItem },
  data () {
    return {
      shopItems: null,
      cart: [],
      cartFields: [
        { label: 'Bezeichnung', key: 'title' },
        { label: 'Preis', key: 'price' },
        { label: 'Menge', key: 'amount' },
        { label: '', key: 'remove' }
      ]
    }
  },
  async created () {
    await this.fetchData()
  },
  computed: {
    queryCart () {
      return JSON.stringify(this.cart.map(item => ({ id: item.id, amount: parseInt(item.amount) })))
    },
    totalCost () {
      const items = this.cart
      if (!items.length) { return 0 }
      if (items.length === 1) {
        return items[0].price * items[0].amount
      }

      return items.reduce((accumulator, item) => ({
        price: (accumulator.amount * accumulator.price) + (item.amount * item.price),
        amount: 1
      })).price
    }
  },
  methods: {
    async fetchData () {
      const response = await this.$http.get(window.location.origin + '/php/GET_ShopItems.php')

      if (response.status === 200) {
        this.shopItems = response.data
      } else {
        this.createToast('Unable to fetch data', 'danger')
      }
    },
    async deleteItem (item) {
      const h = this.$createElement
      const body = h('div', {}, [
        h('div', {}, ['Du bist dabei folgenden Datensatz zu löschen:']),
        h('p', { class: ['m-3'] }, [
          `Id: ${item.id}`, h('br'),
          `Titel: "${item.title}"`
        ])
      ])

      const confirmed = await this.$bvModal.msgBoxConfirm(body, {
        title: 'Datensatz löschen',
        okTitle: 'Löschen',
        cancelVariant: 'inverted',
        cancelTitle: 'Abbrechen',
        hideHeaderClose: true
      })

      if (confirmed) {
        const response = await this.$http.post(
          window.location.origin + '/php/DELETE_ShopItems.php',
          { id: item.id, imagepath: item.imagepath }
        )

        if (response.status === 200) {
          this.createToast('Successfully deleted data', 'success')
        } else {
          this.createToast('Unable to delete data', 'danger')
        }
      }

      this.fetchData()
    },
    addToCart (item) {
      const cartIndex = this.cart.findIndex(cartItem => cartItem.id === item.id)
      if (cartIndex !== -1) {
        this.cart[cartIndex].amount += 1
      } else {
        Vue.set(item, 'amount', 1)
        this.cart.push(item)
      }
    },
    removeFromCart (id) {
      const cartIndex = this.cart.findIndex(cartItem => cartItem.id === id)
      if (cartIndex !== -1) {
        this.cart.splice(cartIndex, 1)
      }
    }
  }
}
</script>
