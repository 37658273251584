<template>
  <div class="musicians">
    <div class="d-flex justify-content-between">
      <h3 class="origin-title">Die Musikanten</h3>
      <b-button v-if="$session.get('logged-in')" variant="primary" to="/musicians/new" class="mb-2">
        <font-awesome-icon icon="plus"/>
      </b-button>
    </div>
    <musician-card v-for="musician in musicians"
                   :key="musician.id"
                   :musician="musician"
                   :displayIcons="$session.get('logged-in')"
                   @delete-musician="deleteMusician">
    </musician-card>
  </div>
</template>

<script>
import MusicianCard from './MusicianCard.vue'

export default {
  name: 'Musicians',
  components: { MusicianCard },
  data () {
    return {
      musicians: null
    }
  },
  async created () {
    await this.fetchData()
  },
  methods: {
    async fetchData () {
      const response = await this.$http.get(window.location.origin + '/php/GET_Musicians.php')
      if (response.status === 200) {
        this.musicians = response.data
      } else {
        this.createToast('Unable to fetch data', 'danger')
      }
    },
    async deleteMusician (musician) {
      const h = this.$createElement
      const body = h('div', {}, [
        h('div', {}, ['Du bist dabei folgenden Datensatz zu löschen:']),
        h('p', { class: ['m-3'] }, [
          `Id: ${musician.id}`, h('br'),
          `Name: "${musician.name}"`
        ])
      ])

      const confirmed = await this.$bvModal.msgBoxConfirm(body, {
        title: 'Datensatz löschen',
        okTitle: 'Löschen',
        cancelVariant: 'inverted',
        cancelTitle: 'Abbrechen',
        hideHeaderClose: true
      })

      if (confirmed) {
        const response = await this.$http.post(
          window.location.origin + '/php/DELETE_Musicians.php',
          { id: musician.id, imagepath: musician.imagepath }
        )

        if (response.status === 200) {
          this.createToast('Successfully deleted data', 'success')
        } else {
          this.createToast('Unable to delete data', 'danger')
        }
      }

      this.fetchData()
    }
  }
}
</script>
