<template>
  <div class="mb-3">
    <h3>Formular</h3>
    <div :class="['form__element', { 'form__element--error': $v.date.$error }]">
      <label class="form__element-label">Datum *</label>
      <b-form-datepicker v-model="$v.date.$model" placeholder=""/>
      <div v-if="!$v.date.required" class="form__error-label">Das Feld muss ausgefüllt sein</div>
    </div>
    <div :class="['form__element', { 'form__element--error': $v.time.$error }]">
      <label class="form__element-label">Zeitraum</label>
      <b-form-input v-model="$v.time.$model"/>
      <div v-if="!$v.description.maxLength" class="form__error-label">Es sind maximal 30 Zeichen erlaubt</div>
    </div>
    <div :class="['form__element', { 'form__element--error': $v.description.$error }]">
      <label class="form__element-label">Beschreibung *</label>
      <ckeditor v-model="$v.description.$model"/>
      <div v-if="!$v.description.required" class="form__error-label">Das Feld muss ausgefüllt sein</div>
      <div v-if="!$v.description.maxLength" class="form__error-label">Es sind maximal 500 Zeichen erlaubt</div>
    </div>
    <div class="d-flex justify-content-end">
      <b-button variant="inverted" to="/events" class="mr-2">Abbrechen</b-button>
      <b-button variant="primary"
                @click="createEvent"
                :disabled="!$v.$anyDirty || $v.$invalid"
      >
        Erstellen
      </b-button>
    </div>
  </div>
</template>
<script>
import { required, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'NewEvent',
  data () {
    return {
      date: null,
      time: '',
      description: ''
    }
  },
  validations: {
    date: {
      required
    },
    time: {
      maxLength: maxLength(30)
    },
    description: {
      required,
      maxLength: maxLength(500)
    }
  },
  methods: {
    async createEvent () {
      const response = await this.$http.post(
        window.location.origin + '/php/POST_Events.php',
        {
          date: this.date,
          time: this.time,
          description: this.description
        }
      )

      if (response.status === 200) {
        this.createToast('Successfully created data', 'success')
      } else {
        this.createToast('Unable to create data', 'danger')
      }

      this.$router.push('/events')
    }
  }
}
</script>
