<template>
  <div class="events">
    <div class="d-flex justify-content-between">
      <h3 class="events-title">Unsere Termine</h3>
      <b-button v-if="$session.get('logged-in')" variant="primary" to="/events/new" class="mb-2">
        <font-awesome-icon icon="plus"/>
      </b-button>
    </div>
    <div>
        Wir freuen uns Sie an unseren Auftritten begrüssen zu dürfen. Hier sehen Sie die kommenden
        Termine und auch wo wir schon gespielt haben. Gerne dürfen Sie uns auch kontaktieren und nachfragen.
    </div>
    <events-table :items="futureEvents"
                  :fields="eventFields"
                  title="Kommende Termine"
                  empty-text="Es sind keine Veranstaltungen geplant"
                  @delete-event="deleteEvent"
    />
    <events-table :items="pastEvents"
                  :fields="eventFields"
                  title="Vergangene Termine"
                  empty-text="Es existieren keine vergangenen Veranstaltungen"
                  @delete-event="deleteEvent"
    />
  </div>
</template>

<script>
import EventsTable from './EventsTable.vue'

export default {
  name: 'Events',
  components: { EventsTable },
  data () {
    return {
      events: [],
      eventFields: [
        { label: 'Datum', key: 'date', sortable: true },
        { label: 'Zeit', key: 'time' },
        { label: 'Veranstaltung', key: 'description' }
      ]
    }
  },
  async created () {
    if (this.$session.get('logged-in')) {
      this.eventFields.push({ label: '', key: 'edit' })
      this.eventFields.push({ label: '', key: 'delete' })
    }

    await this.fetchData()
  },
  computed: {
    dateNow () {
      let now = new Date()
      now.setHours(0, 0, 0, 0)
      return now
    },
    futureEvents () {
      return this.events.filter(event => new Date(event.date) >= this.dateNow)
        .sort((thisDate, nextDate) => new Date(thisDate.date) - new Date(nextDate.date))
    },
    pastEvents () {
      return this.events.filter(event => new Date(event.date) < this.dateNow)
    }
  },
  methods: {
    async fetchData () {
      const response = await this.$http.get(window.location.origin + '/php/GET_Events.php')
      if (response.status === 200) {
        this.events = response.data
      } else {
        this.createToast('Unable to fetch data', 'danger')
      }
    },
    async deleteEvent (event) {
      const h = this.$createElement
      const body = h('div', {}, [
        h('div', {}, ['Du bist dabei folgenden Datensatz zu löschen:']),
        h('p', { class: ['m-3'] }, [
          `Id: ${event.id}`, h('br'),
          `Datum: "${event.date}"`
        ])
      ])

      const confirmed = await this.$bvModal.msgBoxConfirm(body, {
        title: 'Datensatz löschen',
        okTitle: 'Löschen',
        cancelVariant: 'inverted',
        cancelTitle: 'Abbrechen',
        hideHeaderClose: true
      })

      if (confirmed) {
        const response = await this.$http.post(
          window.location.origin + '/php/DELETE_Events.php',
          { id: event.id }
        )

        if (response.status === 200) {
          this.createToast('Successfully deleted data', 'success')
        } else {
          this.createToast('Unable to delete data', 'danger')
        }
      }

      this.fetchData()
    }
  }
}
</script>
