<template>
  <b-row class="new-shop mb-3">
    <b-col lg="8" md="12" class="new-shop__form">
      <h3>Formular</h3>
      <div :class="['form__element', { 'form__element--error': $v.image.$error }]">
        <label class="form__element-label">Bild</label>
        <div class="d-flex">
          <b-form-file v-model="$v.image.$model"
                       placeholder="Bild auswählen..."
                       drop-placeholder="Bild hier ablegen..."
                       browse-text="Durchsuchen"
                       accept="image/*"
                       @change="encodeImage"
          />
          <b-button v-if="hasImage" @click="image = null"><font-awesome-icon icon="times"/></b-button>
        </div>
      </div>
      <div :class="['form__element', { 'form__element--error': $v.title.$error }]">
        <label class="form__element-label">Titel *</label>
        <b-form-input v-model="$v.title.$model"/>
        <div v-if="!$v.title.required" class="form__error-label">Das Feld muss ausgefüllt sein</div>
        <div v-if="!$v.title.maxLength" class="form__error-label">Es sind maximal 50 Zeichen erlaubt</div>
      </div>
      <div :class="['form__element', { 'form__element--error': $v.description.$error }]">
        <label class="form__element-label">Inhalt *</label>
        <ckeditor v-model="$v.description.$model"/>
        <div v-if="!$v.description.required" class="form__error-label">Das Feld muss ausgefüllt sein</div>
        <div v-if="!$v.description.maxLength" class="form__error-label">Es sind maximal 500 Zeichen erlaubt</div>
      </div>
      <div :class="['form__element', { 'form__element--error': $v.price.$error }]">
        <label class="form__element-label">Preis *</label>
        <b-form-input v-model="$v.price.$model"/>
        <div v-if="!$v.price.required" class="form__error-label">Das Feld muss ausgefüllt sein</div>
        <div v-if="!$v.price.decimal" class="form__error-label">Der Wert des Feldes muss numerisch sein</div>
      </div>
      <div class="d-flex justify-content-end">
        <b-button variant="inverted" to="/shop" class="mr-2">Abbrechen</b-button>
        <b-button variant="primary"
                  @click="createShopItem"
                  :disabled="!$v.$anyDirty || $v.$invalid"
        >
          Erstellen
        </b-button>
      </div>
    </b-col>
    <b-col v-if="hasImage" lg="4" md="12" class="new-shop__preview object-contain">
      <h3>Bild-Vorschau</h3>
      <b-img class="preview__image" :src="imageBase64" alt="Preview-image"/>
    </b-col>
  </b-row>
</template>
<script>
import { required, decimal, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'NewShop',
  data () {
    return {
      image: null,
      imageBase64: null,
      title: '',
      description: '',
      price: null
    }
  },
  validations: {
    image: {},
    title: {
      required,
      maxLength: maxLength(50)
    },
    description: {
      required,
      maxLength: maxLength(500)
    },
    price: {
      required,
      decimal
    }
  },
  computed: {
    hasImage () {
      return !!this.image
    }
  },
  methods: {
    encodeImage (value) {
      this.base64Encode(value.target.files[0]).then(value => {
        this.imageBase64 = value
      }).catch(() => {
        this.imageBase64 = null
      })
    },
    async createShopItem () {
      let formData = new FormData()
      formData.append('title', this.title)
      formData.append('description', this.description)
      formData.append('image', this.image)
      formData.append('price', this.price)

      const response = await this.$http.post(
        window.location.origin + '/php/POST_ShopItems.php',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )

      if (response.status === 200) {
        this.createToast('Successfully created data', 'success')
      } else {
        this.createToast('Unable to create data', 'danger')
      }

      this.$router.push('/shop')
    }
  }
}
</script>