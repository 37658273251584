<template>
  <div id="app">
      <div class="header">
        <Navigation/>
      </div>
      <div class="content mb-5">
        <b-container class="pt-4">
          <div class="content__container">
            <router-view/>
          </div>
        </b-container>
      </div>
    </div>
</template>

<script>
import Navigation from './components/Navigation.vue'

export default {
  name: 'App',
  components: { Navigation }
}
</script>
