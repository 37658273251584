<template>
  <div class="home">
    <h1 class="home__title">Alpenstadel Musikanten</h1>
    <div class="home__introduction">
      <img
        src="../img/group_picture.png"
        alt="group_picture"
        class="home__introduction-image"
      />
      <h3 class="home__introduction-title mt-1">
        Gute Laune aus der Schweizer Bodenseeregion
      </h3>
      <div class="home__introduction-text">
        Die Alpenstadel Musikanten spielen aus Begeisterung zur Oberkrainer
        Musik und zum volkstümlichen Schlager, Musik aus der Schweiz,
        Deutschland, Österreich und Slowenien. Die Lieder sind zu 100% live
        gespielt und die natürliche und fröhliche Stimmung der Musiker überträgt
        sich auf das Publikum. Die Alpenstadel Musikanten sind die ideale Musik
        für Ihren Anlass, ob Hochzeit, Turnerfest, Dorfunterhaltung in der
        Gartenwirtschaft oder im Festzelt.
        <br />
        <b>Musik die gute Laune macht!</b>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>
