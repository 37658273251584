import VueRouter from 'vue-router';
import Home from './components/Home.vue'
import Origin from './components/Origin.vue'
import Musicians from './components/Musicians/Musicians.vue'
import NewMusician from './components/Musicians/NewMusician.vue'
import EditMusician from './components/Musicians/EditMusician.vue'
import Events from './components/Events/Events.vue'
import NewEvent from './components/Events/NewEvent.vue'
import EditEvent from './components/Events/EditEvent.vue'
import Contact from './components/Contact.vue'
import Friends from './components/Friends/Friends.vue'
import NewFriend from './components/Friends/NewFriend.vue'
import EditFriend from './components/Friends/EditFriend.vue'
import Shop from './components/Shop/Shop.vue'
import NewShop from './components/Shop/NewShop.vue'
import EditShop from './components/Shop/EditShop.vue'
import Order from './components/Order/Order.vue'
import Videos from './components/Videos.vue'
import Login from './components/Login.vue'
import NotFound from './components/Filler/NotFound.vue'

let routes=[
  { path: '/', component: Home },
  { path: '/origin', component: Origin },
  { path: '/musicians', component: Musicians },
  { path: '/musicians/new', component: NewMusician },
  { path: '/musicians/edit/:id', component: EditMusician },
  { path: '/events', component: Events },
  { path: '/events/new', component: NewEvent },
  { path: '/events/edit/:id', component: EditEvent },
  { path: '/contact', component: Contact },
  { path: '/friends', component: Friends },
  { path: '/friends/new', component: NewFriend },
  { path: '/friends/edit/:id', component: EditFriend },
  { path: '/shop', component: Shop },
  { path: '/shop/new', component: NewShop },
  { path: '/shop/edit/:id', component: EditShop },
  { path: '/shop/order', component: Order },
  { path: '/videos', component: Videos },
  { path: '/login', component: Login },
  { path: '/php/*' },
  { path: '/*', component: NotFound }
];

export default new VueRouter({
  routes,
  mode: 'history'
});
