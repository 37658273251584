<template>
  <div>
    <h1>:(</h1>
    <div>Es konnte keine Verbindung zur Datenbank hergestellt werden</div>
  </div>
</template>

<script>
export default {
  name: 'NoDbConnection'
}
</script>
